const { NODE_ENV } = process.env

exports.onRouteUpdate = () => {
  function getCookie(name) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
    return v ? v[2] : null
  }

  function giveConsent() {
    if (NODE_ENV == 'production') {
      _paq.push(['setConsentGiven'])
    }
  }

  function setCookie(name, value, days) {
    giveConsent()
    const d = new Date()
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
    document.cookie =
      name +
      '=' +
      value +
      ';path=/;expires=' +
      d.toGMTString() +
      ';SameSite=None; Secure'
  }

  const accept = document.getElementsByClassName('cookie-accept')

  for (let i = 0; i < accept.length; i++) {
    accept[i].addEventListener('click', function() {
      setCookie('showCookiebar', 'no', 180)
    })
  }

  const showCookieBar = getCookie('showCookiebar')

  if (showCookieBar !== 'no') {
    const el = document.getElementById('cookie-bar')
    el.style.display = 'block'
  } else {
    giveConsent()
  }
}
