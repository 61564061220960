// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-bedankt-js": () => import("./../../../src/pages/contact-bedankt.js" /* webpackChunkName: "component---src-pages-contact-bedankt-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-energetisch-renoveren-js": () => import("./../../../src/pages/energetisch-renoveren.js" /* webpackChunkName: "component---src-pages-energetisch-renoveren-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-wie-js": () => import("./../../../src/pages/wie.js" /* webpackChunkName: "component---src-pages-wie-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projecten-js": () => import("./../../../src/templates/projecten.js" /* webpackChunkName: "component---src-templates-projecten-js" */)
}

